import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { NotificationMessage } from '@priva/components/notifications';
import { Parity } from '@priva/next-model';

import { ErrorDetail } from '.';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlingService {
    private translate = inject(TranslateService);

    public errorToMessage(error: ErrorDetail): NotificationMessage {
        if (!error.details) {
            return this.getErrorMessage(error);
        }

        let notificationMessage: NotificationMessage;
        if (this.isModbusError(error)) {
            notificationMessage = this.getModbusError(error);
        } else if (this.isDatapointError(error)) {
            notificationMessage = this.getDatapointError(error);
        }
        if (!notificationMessage) {
            notificationMessage = this.getErrorMessage(error);
        }
        return notificationMessage;
    }

    private getErrorMessage(error: ErrorDetail): NotificationMessage {
        let errorMessage: string = error.messageKey ? this.translate.instant(error.messageKey) : undefined;
        if (!errorMessage || errorMessage === error.messageKey || typeof errorMessage !== 'string') {
            errorMessage = error.message;
        }
        return { emphasized: error.title, message: errorMessage, params: error.details };
    }

    private isDatapointError(error: ErrorDetail): boolean {
        return (
            error.details?.featureIdsNotValid ||
            error.details?.featureIdsOutOfRange ||
            error.details?.featureIdsWrongResolution
        );
    }

    private getDatapointError(error: ErrorDetail): NotificationMessage {
        if (error.details?.featureIdsNotValid) {
            return {
                emphasized: error.title,
                message: this.getDatapointNotValidMessage(error),
            };
        } else if (error.details?.featureIdsOutOfRange) {
            return {
                emphasized: error.title,
                message: this.getDatapointOutOfRangeMessage(error),
            };
        } else if (error.details?.featureIdsWrongResolution) {
            return {
                emphasized: error.title,
                message: this.getDatapointWrongResolutionMessage(error),
            };
        }
        return undefined;
    }

    private getDatapointNotValidMessage(error: ErrorDetail): string {
        return this.translate.instant(`${error.messageKey}.NOT_VALID_DEFAULT_VALUE`);
    }
    private getDatapointOutOfRangeMessage(error: ErrorDetail): string {
        return this.translate.instant(`${error.messageKey}.OUT_OF_RANGE`);
    }
    private getDatapointWrongResolutionMessage(error: ErrorDetail): string {
        return this.translate.instant(`${error.messageKey}.WRONG_RESOLUTION`);
    }

    private isModbusError(error: ErrorDetail): boolean {
        return (
            error.details?.modbusErrors ||
            error.details?.modBusErrorDevice ||
            error.details?.maxAmountOnRS485 ||
            (error.details?.usedBy && error.details?.address)
        );
    }

    private getModbusError(error: ErrorDetail): NotificationMessage {
        if (error.details?.modbusErrors) {
            return {
                emphasized: error.title,
                message: this.getModbusClientSettingErrorsMessage(error),
            };
        } else if (error.details?.modBusErrorDevice) {
            return {
                emphasized: error.title,
                message: this.getModbusClientSettingErrorsMessageDetails(error, error.details).join('; '),
            };
        } else if (error.details?.maxAmountOnRS485) {
            return {
                emphasized: error.title,
                message: this.getModbusServerMaxAmountOnRS485ReachedMessage(error),
            };
        } else if (error.details?.usedBy && error.details?.address) {
            return {
                emphasized: error.title,
                message: this.getModbusServerUsedByMessage(error),
            };
        }
        return undefined;
    }

    private getModbusServerMaxAmountOnRS485ReachedMessage(error: ErrorDetail): string {
        return this.translate.instant(`${error.messageKey}.MAX_AMOUNT_ON_RS485_REACHED`, {
            maxAmountOnRS485: error.details.maxAmountOnRS485,
            bus: error.details.bus,
        });
    }

    private getModbusServerUsedByMessage(error: ErrorDetail): string {
        return this.translate.instant(`${error.messageKey}.NOT_UNIQUE`, {
            usedBy: this.translate.instant(error.details.usedBy),
            address: error.details.address,
        });
    }

    private getModbusClientSettingErrorsMessage(error: ErrorDetail): string {
        let messages: string[] = [];
        error.details.modbusErrors.forEach((m) => {
            messages = messages.concat(this.getModbusClientSettingErrorsMessageDetails(error, m));
        });
        return messages.join('; ');
    }

    private getModbusClientSettingErrorsMessageDetails(error: ErrorDetail, m: any): string[] {
        const messages: string[] = [];
        if (m.requiredBaudRate) {
            messages.push(
                this.translate.instant(`${error.messageKey}.MODBUS_BAUDRATE`, {
                    deviceName: m.controllerName,
                    baudRate: m.requiredBaudRate,
                    bus: m.busNumber,
                    notSupportedBaudrateDevices: m.modBusErrorDevice
                        ? m.modBusErrorDevice
                        : m.notSupportedBaudrateDevices.join(', '),
                }),
            );
        }
        if (
            m.requiredNumberOfDataBits != null &&
            m.requiredParity != null &&
            m.requiredNumberOfStopBits != null
        ) {
            messages.push(
                this.translate.instant(`${error.messageKey}.MODBUS_SERIALPORTPARAMETERCOMBINATION`, {
                    deviceName: m.controllerName,
                    dataBits: m.requiredNumberOfDataBits,
                    parity: Object.keys(Parity)[m.requiredParity],
                    stopBits: m.requiredNumberOfStopBits,
                    bus: m.busNumber,
                    notSupportedSerialPortParameterCombinations: m.modBusErrorDevice
                        ? m.modBusErrorDevice
                        : m.notSupportedSerialPortParameterCombinations.join(', '),
                }),
            );
        }
        if (messages.length === 0) {
            messages.push(error.message);
        }
        return messages;
    }
}
